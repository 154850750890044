import { IAffiliate, IAllowedUrlParams, IBanner, IConfig, IGame, IPageConfig } from "./interfaces";
import * as $ from "jquery";


window["dataLayer"] = [];
let dataLayer: any[] = window["dataLayer"];
window["adsByGoogle"] = [];
let adsByGoogle = window["adsByGoogle"];


export class App {
    affiliate: IAffiliate;
    games: IGame[];
    lang: string;
    selectedGame: IGame;
    banner: IBanner;

    defaultLocalization: string;
    localization: string;
    config: IConfig;

    backgroundHtmlContainer: HTMLElement;

    topBarHtmlContainer: HTMLElement;
    bannerHtmlContainer: HTMLElement;
    bannerHtmlElement: HTMLElement;

    gamesHtml: HTMLElement;
    gamesHtmlWrapper: HTMLElement;
    gameHtmlTemplate: HTMLElement;

    backButtonHtmlElement: HTMLElement;

    iframeHtmlElement: HTMLElement;

    loaderHtmlContainer: HTMLElement;

    private configUrl: string;

    constructor(configUrl: string) {
        this.configUrl = configUrl;
    }

    public async start(): Promise<void> {

        await this.init();

        this.changeGame(this.selectedGame);

        this.attachOnGameClicked();
        this.attachBackButtonClicked();
        this.attachBannerClicked();

        if (this.selectedGame) {
            this.fullscreen();
        } else {
            const fixParentHeightInterval = setInterval(() => {
                if (this.gamesHtml.style.cssText) {
                    this.gamesHtml.style.cssText = "";
                    clearInterval(fixParentHeightInterval);
                }
            }, 500);
            // this.attachOnResize();
        }
    }

    private async init(): Promise<void> {
        const startTimestamp = Date.now();

        this.affiliate = undefined;
        this.games = undefined;
        this.lang = undefined;
        this.selectedGame = undefined;
        this.banner = undefined;
        this.defaultLocalization = undefined;
        this.localization = undefined;
        this.config = undefined;

        this.backgroundHtmlContainer = $(".background")[0];

        this.topBarHtmlContainer = $(".top-bar-container")[0];
        this.bannerHtmlContainer = $(".banner-container")[0];
        this.bannerHtmlElement = $(".banner")[0];

        this.gameHtmlTemplate = $(".templates > .game-wrapper")[0];
        this.gamesHtml = $(".games")[0];
        this.gamesHtmlWrapper = $(".games-wrapper")[0];

        this.backButtonHtmlElement = $(".back-button")[0];

        this.iframeHtmlElement = $(".game-iframe")[0];

        this.loaderHtmlContainer = $(".loader-container")[0];

        await this.buildLocalConfigs();

        this.generateGamesHtml();

        if (this.affiliate.gtmId) {
            this.loadGoogleTagManager(this.affiliate.gtmId);
        }

        // this.loadAdSense();

        const endTimestamp = Date.now();

        const minDelay = 300;
        let currentDelayMs = minDelay - (endTimestamp - startTimestamp);
        if (currentDelayMs < 0) {
            currentDelayMs = 0;
        }
        const delay = Math.min(minDelay, currentDelayMs);
        setTimeout(() => {
            $(this.loaderHtmlContainer).css("opacity", 0);
            setTimeout(() => {
                $(this.loaderHtmlContainer).remove();
            }, 500);
        }, delay);
    }

    private async buildLocalConfigs(): Promise<void> {
        this.config = await this.getConfig();

        const params: IAllowedUrlParams = this.getUrlQueryParams();
        this.validateQueryParams(params, this.config);

        // lang
        this.lang = params.lang || "en";
        this.defaultLocalization = await this.getTranslation("en");
        try {
            this.localization = await this.getTranslation(this.lang);
        } catch (e) {
            this.localization = this.defaultLocalization;
        }
        // affiliate
        this.affiliate = this.config.affiliates.find(affiliate => affiliate.id === params.affiliateId);
        this.changeSkin(this.affiliate.pageConfig);

        // banner
        if (this.affiliate.defaultBannerId) {
            this.banner = this.config.banners.find(banner => banner.id === this.affiliate.defaultBannerId);
            $(this.bannerHtmlElement).attr("src", this.banner.imageUrl);
            $(this.bannerHtmlElement).addClass("clickable");
        }
        // games
        this.games = [];
        this.affiliate.gamesIds.forEach(gameId => {
            this.games.push(this.config.games.find(game => game.id === gameId));
        });
        // game
        this.selectedGame = this.config.games.find(game => game.id === params.gameId);
    }

    private getTranslate(key: string): string {
        if (this.localization && this.localization[key]) {
            return this.localization[key];
        }
        return this.defaultLocalization[key];
    }

    private getConfig(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            let xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState == 4) {
                    if (this.status == 200) {
                        try {
                            let body = JSON.parse(this.response);
                            return resolve(body);
                        } catch (e) {
                            return reject(e);
                        }
                    } else {
                        return reject(this.response);
                    }
                }
            };
            xhttp.open("GET", `${this.configUrl}config.json`, true);
            xhttp.send();
        });
    }

    private getTranslation(lang: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            let xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState == 4) {
                    if (this.status == 200) {
                        try {
                            let body = JSON.parse(this.response);
                            return resolve(body);
                        } catch (e) {
                            return reject(e);
                        }
                    } else {
                        return reject(this.response);
                    }
                }
            };
            xhttp.open("GET", `${this.configUrl}translations/${lang}.json`, true);
            xhttp.send();
        });
    }

    private generateGamesHtml() {
        this.gamesHtmlWrapper.innerHTML = "";
        for (let i = 0; i < this.games.length; i++) {
            let newNode = this.gameHtmlTemplate.cloneNode(true);
            let el = this.gamesHtmlWrapper.appendChild(newNode);
            $(el).find(".game-thumbnail").attr("src", this.games[i].thumbnail);
            $(el).find(".game-name").html(this.getTranslate(this.games[i].id));
            if (!this.games[i].url) {
                $(el).find(".game-coming-soon").addClass("visible");
            }
        }
    }

    private attachOnGameClicked(): void {
        for (let i = 0; i < this.games.length; i++) {
            const gameEl = $(".game-wrapper")[i];
            if (this.games[i].url) {
                $(gameEl).on("click", () => {
                    this.changeGame(this.games[i]);
                    dataLayer.push({ "event": `game:${this.games[i].id}`, "publisher": this.affiliate.id });
                });
            }
        }
    }

    private attachBackButtonClicked(): void {
        $(this.backButtonHtmlElement).on("click", () => {
            this.changeGame();

            dataLayer.push({ "event": "back", "publisher": this.affiliate.id });
        });
    };

    private attachBannerClicked(): void {
        if (this.banner) {
            $(this.bannerHtmlContainer).on("click", () => {
                dataLayer.push({ "event": `banner:${this.banner.id}`, "publisher": this.affiliate.id });
                window.open(`${this.banner.redirectUrl}?affiliateId=${this.affiliate.id}`, "_blank");
            });
        }
    }

    private attachOnResize(): void {
        window.addEventListener("resize", (event) => {
            document.body.style.width = "101%";
            setTimeout(() => {
                document.body.style.width = "100%";
                console.log("BRAVO");
            }, 100);
        });
    }

    private getUrlQueryParams(): any {
        var queryParams = {};
        try {
            let query = window.location.href.slice(window.location.href.indexOf("?") + 1);
            let parsedQuery = query.replace(/%20/g, " ");
            var hashes = parsedQuery.split("&");
            for (var i = 0; i < hashes.length; i++) {
                var hash = hashes[i].split("=");
                var values = hash[1].split(",");
                queryParams[hash[0]] = values.length > 1 ? values : values[0];
            }
        } catch (e) {
        }
        return queryParams;
    }

    private validateQueryParams(params: IAllowedUrlParams, config: IConfig): void {
        const allowedParams: IAllowedUrlParams = {};

        // Affiliate
        let affiliate = config.affiliates.find(affiliate => affiliate.id == params.affiliateId);
        if (!!affiliate) {
            allowedParams.affiliateId = params.affiliateId;
        } else {
            affiliate = config.affiliates[0];
            allowedParams.affiliateId = affiliate.id;
        }

        // SelectedGame
        if (params.gameId) {
            if (affiliate.gamesIds.find(gameId => gameId == params.gameId)) {
                allowedParams.gameId = params.gameId;
            }
        }

        // Lang
        if (params.lang) {
            if (!!config.availableLanguages.find(lang => lang == params.lang)) {
                allowedParams.lang = params.lang;
            }
        }

        if (JSON.stringify(params) != JSON.stringify(allowedParams)) {
            window.location.replace(this.toQueryUrl(window.origin, allowedParams));
        }
    }

    private loadGoogleTagManager(gtmId: string): void {
        window["dataLayer"] = window["dataLayer"] || [];
        window["dataLayer"].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        let firstScript = document.getElementsByTagName("script")[0];
        let gtmScriptElement = document.createElement("script");
        gtmScriptElement.async = true;
        gtmScriptElement.src = "https://www.googletagmanager.com/gtm.js?id=" + gtmId;
        firstScript.parentNode.insertBefore(gtmScriptElement, firstScript);


        let gtmBodyElement = document.createElement("noscript");
        let firstBodyChild = document.getElementsByTagName("body")[0].firstChild;
        firstBodyChild.parentNode.insertBefore(gtmBodyElement, firstBodyChild);
        const gtmBodyUrl = "https://www.googletagmanager.com/ns.html?id=" + gtmId;
        let gtmIframe = document.createElement("iframe");
        gtmIframe.src = gtmBodyUrl;
        gtmIframe.setAttribute("width", "0");
        gtmIframe.setAttribute("height", "0");
        gtmIframe.setAttribute("style", "display:none;visibility:hidden");
        gtmBodyElement.appendChild(gtmIframe);
    }

    private loadAdSense(clientId = "ca-pub-3883658498011041"): void {
        let firstBodyChild = document.getElementsByTagName("body")[0].firstChild;

        let adSenseScriptElement = document.createElement("script");
        adSenseScriptElement.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
        firstBodyChild.parentNode.insertBefore(adSenseScriptElement, firstBodyChild);


        const adsByGoogle = window["adsbygoogle"] || [];
        adsByGoogle.push({});
        // adsByGoogle.push({
        //     google_ad_client: clientId,
        //     enable_page_level_ads: true
        // });
    }

    private toQueryUrl(url: string, params: any): string {
        let queryString = Object.keys(params).map((key) => {
            return key + "=" + params[key];
        }).join("&");
        let queryPrefix = url.includes("?") ? "&" : "?";
        queryString = queryString ? queryPrefix + queryString : "";
        return url + queryString;
    }

    private changeSkin(pageConfig: IPageConfig): void {
        if (!!pageConfig) {
            if (pageConfig.background) {
                $(this.topBarHtmlContainer).css("background", pageConfig.background);
                $(this.backgroundHtmlContainer).css("background", pageConfig.background);
            }
        }
    }

    private changeGame(game?: IGame): void {
        this.selectedGame = game;
        let url = "";
        if (game) {
            url = this.selectedGame.url = this.toQueryUrl(this.selectedGame.url, { lang: this.lang });
        }
        $(this.iframeHtmlElement).attr("src", url);
        $(this.iframeHtmlElement).css("display", game ? "block" : "none");
        $(this.backButtonHtmlElement).css("display", game ? "block" : "none");
        $(this.gamesHtml).css("display", !game ? "flex" : "none");
        window.scrollTo({
            top: 0,
            left: 0,
        });
    }

    private fullscreen(): void {
        document.body.style.padding = "0px";
        $(this.iframeHtmlElement).css("height", "100vh");
        $(this.topBarHtmlContainer).css("display", "none");
    }
}
